import React, { SVGProps, FunctionComponent, SVGAttributes } from "react"
import { css, SerializedStyles } from "@emotion/react"
import { getLink, getLinkUrl } from "./links/links.utils"
import { navigate } from "gatsby"
import { Lang } from "./links/links.types"
import { backgroundPrimaryColor, fillMap } from "./variables"

export const mapProps: SVGProps<any> = {
  fill: fillMap,
  stroke: backgroundPrimaryColor,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1",
}
export const mapStyle = css`
  max-height: 60vh;
  text {
    stroke: none;
  }
`

export const defaultTextStyle = css`
  fill: black;
  font-weight: bold;
  rect {
    fill: transparent;
  }
  text::selection {
    fill: white;
  }
`
export const propsFor = ({
  city,
  lang,
  basicTextStyle = defaultTextStyle,
  hoveredTextStyle = defaultTextStyle,
}: {
  city: string
  lang: Lang
  basicTextStyle?: SerializedStyles
  hoveredTextStyle?: SerializedStyles
}) => {
  const link = getLink(city)
  if (link.published) {
    return {
      css: hoveredTextStyle,
      onClick: () => navigate(getLinkUrl(lang)(city)),
    }
  }
  return {
    css: basicTextStyle,
  }
}

export const PinPoint: FunctionComponent<SVGAttributes<any>> = (props) => {
  return (
    <svg version="1.1" viewBox="0 0 512 512" {...props}>
      <path
        d="M441.554,133.088C423.517,74.8,376.1,27.369,317.812,9.33C256.12-9.78,190.482,0.841,140.381,37.757
			c-49.732,36.661-79.419,95.389-79.419,157.093c0,42.567,13.466,83.008,38.933,116.944L256.02,512l156.125-200.221
			C450.258,260.963,460.984,195.832,441.554,133.088z M256.02,299.88c-57.908,0-105.031-47.123-105.031-105.031
			c0-57.908,47.123-105.031,105.031-105.031c57.908,0,105.031,47.123,105.031,105.031C361.051,252.757,313.928,299.88,256.02,299.88
			z"
      />
      <path
        d="M256.02,120.027c-41.365,0-75.022,33.457-75.022,74.822c0,41.365,33.657,75.022,75.022,75.022
			c41.365,0,75.022-33.657,75.022-75.022C331.043,153.484,297.385,120.027,256.02,120.027z"
      />
    </svg>
  )
}

export const PinAirport: FunctionComponent<SVGAttributes<any>> = (props) => (
  // <svg viewBox="-79 0 512 512" {...props}>
  <g {...props}>
    <circle r="160" cx="180" cy="180" fill="white" />
    <path d="m188 150.070312-9.851562-17.65625h-18.15625l6.335937 17.65625zm0 0" />
    <path d="m160.121094 167.730469h-37.59375c-1.46875-.003907-2.921875-.25-4.3125-.726563-.289063-.09375-.574219-.207031-.851563-.335937l-37.914062-17.304688v4.007813l41.714843 32.007812h45.855469c.125 0 .226563.066406.351563.070313 1.160156.183593 2.304687.464843 3.417968.847656.113282.054687.238282.042969.351563.105469.324219.230468.636719.480468.925781.753906.527344.34375 1.015625.742188 1.453125 1.191406.671875.894532 1.25 1.851563 1.722657 2.863282.183593.582031.304687 1.183593.363281 1.789062.109375.398438.1875.800781.238281 1.207031 0 .128907-.066406.238281-.070312.367188-.058594.574219-.175782 1.144531-.34375 1.699219-.109376.585937-.28125 1.160156-.507813 1.710937-.050781.113281-.042969.238281-.101563.347656l-16.484374 31.183594h13.992187l32.636719-40.820313c2.183594-2.710937 5.753906-3.882812 9.117187-3 .511719-.15625 1.042969-.261718 1.574219-.316406h37.605469c7.808593-.347656 15.148437-3.800781 20.398437-9.585937-2.113281-1.054688-4.171875-2.222657-6.164062-3.492188-4.359375-2.53125-9.214844-4.09375-14.234375-4.578125h-93.117188zm0 0" />
    <path d="m0 176.550781c0 87.148438 143.335938 271.128907 176.550781 312.589844 33.214844-41.464844 176.550781-225.460937 176.550781-312.589844 0-97.507812-79.042968-176.550781-176.550781-176.550781-97.507812 0-176.550781 79.042969-176.550781 176.550781zm335.449219 0c0 87.757813-71.140625 158.898438-158.898438 158.898438-87.753906 0-158.894531-71.140625-158.894531-158.898438 0-87.753906 71.140625-158.894531 158.894531-158.894531 87.714844.097656 158.796875 71.179688 158.898438 158.894531zm0 0" />
    <path d="m35.308594 176.550781c0 78.007813 63.238281 141.242188 141.242187 141.242188 78.007813 0 141.242188-63.234375 141.242188-141.242188 0-78.003906-63.234375-141.242187-141.242188-141.242187-77.96875.089844-141.152343 63.273437-141.242187 141.242187zm156.933594-55.117187 15.972656 28.636718h45.046875c7.992187.445313 15.769531 2.757813 22.707031 6.746094 5.710938 3.101563 15.339844 8.339844 15.339844 19.117188 0 16.8125-24.628906 27.101562-38.046875 27.101562h-37.15625l-31.597657 39.5c-2.292968 2.984375-5.867187 4.703125-9.632812 4.636719h-25.160156c-4.382813-.003906-8.441406-2.3125-10.6875-6.078125-2.25-3.761719-2.351563-8.429688-.277344-12.292969l13.621094-25.765625h-33.023438c-2.90625.003906-5.722656-1.03125-7.9375-2.914062l-44.742187-34.308594c-3.066407-2.378906-4.867188-6.035156-4.875-9.917969v-14.566406c-.007813-4.203125 2.101562-8.128906 5.613281-10.441406 3.511719-2.308594 7.953125-2.695313 11.808594-1.023438l44.300781 20.207031h24.054687l-5.335937-14.859374c-1.835937-5.027344-1.148437-10.628907 1.84375-15.070313 2.234375-3.328125 5.96875-5.34375 9.980469-5.382813h27.390625c4.550781.074219 8.691406 2.640626 10.792969 6.675782zm0 0" />
  </g>
)
